const getPlayerSeasonStats = async ({ player_ID }) => {
  return await fetch(
    (window.ENV?.REACT_APP_OPTA_URL || process.env.REACT_APP_OPTA_URL) +
      "/opta?url=https://api.stats.com/v1/stats/baseball/mlb/participants/" +
      player_ID
  )
    ?.then((res) => res.json())
    .then(async (player_data) => {
      return await fetch(
        (window.ENV?.REACT_APP_OPTA_URL || process.env.REACT_APP_OPTA_URL) +
          "/opta?url=https://api.stats.com/v1/stats/baseball/mlb/stats/players/" +
          player_ID
      )
        ?.then((res) => res.json())
        .then((season_data) => {
          player_data = player_data?.apiResults?.[0]?.league?.players?.[0];
          season_data =
            season_data?.apiResults?.[0]?.league?.players?.[0]?.seasons?.[0]
              ?.eventType?.[0]?.splits?.[0]?.playerStats;
          return { ...player_data, ...season_data };
        });
    });
};

export default {
  getPlayerSeasonStats,
};
