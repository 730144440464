import React, { useContext, useState } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";
import { ControlContext } from "../../contexts/ControlContext";
import { FiPlusCircle, FiArchive } from "react-icons/fi";
import PageList from "./PageList";
import ShowList from "./ShowList";
import BarLoader from "react-spinners/BarLoader";

import { UIContext } from "../../contexts/UIContext";
import { UserContext } from "../../contexts/UserContext";
import { IoIosInfinite } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckboxOutline } from "react-icons/io";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 0.5em;
  border-bottom: 1px solid #334056;
  align-items: center;
`;

const Button = styled.div`
  display: flex;
  background-color: #252e40;
  padding: 0.5em;
  border-radius: 4px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
`;

const TextInput = styled.div`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;

  min-width: 100px;
  display: flex;
  height: 15px;
  align-items: center;
`;

const PlaylistIcon = styled(IoIosInfinite)`
  color: ${(props) => (props.on ? "#00d0ff" : "")};
  margin-right: 5px;
  font-size: 1.5em;
  cursor: pointer;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const Visible = styled.div`
  display: flex;
  margin-right: 0.5em;
  font-size: 1.2em;
  margin-left: auto;
`;
export default function Show({ showView, setShowView }) {
  const {
    pages,
    selectPage,
    selectedPage,
    selectedShow,
    shows,
    selectShow,
    createShow,
    deleteShow,
    deleteFolder,
    renameShow,
    renameFolder,
    deletePage,
    duplicateShow,
    renamePage,
    setDuration,
    setEngine,
    selectedShowFolder,
    archiveShow,
    createShowFolder,
    setPages,
    folders,
    getEdits,
    setSelectedShow,
    loading,
    exportShow,
    importShow,
    importPages,
  } = useContext(ProjectContext);
  const {
    animate,
    animateOff,
    themes,
    setPlaylistOn,
    playlistOn,
    playlistLoop,
    setPlaylistLoop,
  } = useContext(ControlContext);
  const { pageNumber } = useContext(UIContext);
  const { user } = useContext(UserContext);

  return (
    <Main>
      <Header>
        {!loading && !selectedShow && (
          <div style={{ height: "20px" }}>Select Show...</div>
        )}

        {selectedShow && (
          <Button
            onClick={() => {
              setShowView(showView ? 0 : 1);
              setSelectedShow();
            }}
          >
            {selectedShow?.name}
          </Button>
        )}
        {showView === 0 && (
          <Right>
            {pages.some((p) => p.duration) && !playlistOn && (
              <>
                Loop{" "}
                <Visible
                  onClick={(e) => {
                    setPlaylistLoop(!playlistLoop);
                  }}
                >
                  {playlistLoop === true && <IoMdCheckboxOutline />}
                  {playlistLoop === false && <MdCheckBoxOutlineBlank />}
                </Visible>
              </>
            )}
            {pages.some((p) => p.duration) && playlistOn && (
              <span style={{ marginRight: "1em" }}>
                {playlistLoop ? "looping" : ""}
              </span>
            )}
            {pages.some((p) => p.duration) && (
              <PlaylistIcon
                on={playlistOn}
                onClick={() => {
                  setPlaylistOn(!playlistOn);
                }}
              />
            )}

            <TextInput>{pageNumber || " "}</TextInput>
          </Right>
        )}
      </Header>
      <Content>
        {showView === 0 && (
          <PageList
            getEdits={getEdits}
            pageNumber={pageNumber}
            pages={pages}
            selectPage={selectPage}
            selectedPage={selectedPage}
            animate={animate}
            animateOff={animateOff}
            deletePage={deletePage}
            renamePage={renamePage}
            setDuration={setDuration}
            setEngine={setEngine}
          />
        )}
        {!loading && showView === 1 && (
          <ShowList
            themes={themes}
            selectedShowFolder={selectedShowFolder}
            shows={shows}
            deleteShow={deleteShow}
            deleteFolder={deleteFolder}
            renameShow={renameShow}
            renameFolder={renameFolder}
            duplicateShow={duplicateShow}
            archiveShow={archiveShow}
            folders={folders}
            selectShow={(showName) => {
              selectShow(showName);
              setShowView(0);
            }}
            user={user}
            createShow={createShow}
            setShowView={setShowView}
            createShowFolder={createShowFolder}
            exportShow={exportShow}
            importShow={importShow}
            importPages={importPages}
          />
        )}
        {loading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BarLoader color={"#db0a41"} loading={loading} size={150} />
          </div>
        )}
      </Content>
    </Main>
  );
}
