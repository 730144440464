import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { ControlContext } from "../../../contexts/ControlContext";
const Input = styled.input`
  display: flex;
  justify-content: center;
  margin: 0.5em;
  background-color: ${(props) =>
    props.overwrite ? "#db0a41" : props.theme.secondaryBackgroundColour};
  border-radius: 4px;
  outline: none;
  border: none;
  color: #fff;
  padding: 0.5em;
  font-weight: 100;
  font-size: 1.2em;
`;
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;
const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;
function GameSelect({ preview }) {
  const {
    optaWidgets,
    setOptaWidgets,
    selectedEdit,
    updateOpta,
    setEdits,
    edits,
  } = useContext(ProjectContext);

  const [events, setEvents] = useState();
  const [homePlayers, setHomePlayers] = useState();
  const [awayPlayers, setAwayPlayers] = useState();
  const [playerData, setPlayerData] = useState();

  const [widget, setWidget] = useState();

  useEffect(() => {
    if (optaWidgets?.find((o) => o.name === "mlb")) {
      let widget = optaWidgets?.find((o) => o.name === "mlb");
      setWidget(widget);

      getData({
        date: widget.date,
        eventID: widget.event_id,
        playerID: widget.player_id,
      });
    } else {
      setWidget();
      setPlayerData();
      setEvents();
      setHomePlayers();
      setAwayPlayers();
    }
  }, [optaWidgets]);

  async function getData({ date, eventID, playerID }) {
    let events_data = await fetch(
      (window.ENV?.REACT_APP_OPTA_URL || process.env.REACT_APP_OPTA_URL) +
        "/opta?url=" +
        encodeURI("http://api.stats.com/v1/stats/baseball/mlb/events") +
        "&params=" +
        encodeURI("date=" + date)
    )
      ?.then((res) => res.json())
      .then((data) => {
        return data?.apiResults?.[0]?.league?.season?.eventType?.[0]?.events;
      });

    setEvents(events_data);
    let event = events_data?.find(
      (e) => parseInt(e.eventId) === parseInt(eventID)
    );

    if (event) {
      let homePlayers = await fetch(
        (window.ENV?.REACT_APP_OPTA_URL || process.env.REACT_APP_OPTA_URL) +
          "/opta?url=https://api.stats.com/v1/stats/baseball/mlb/participants/teams/" +
          event?.teams?.find((t) => t.teamLocationType?.name === "home")?.teamId
      )
        ?.then((res) => res.json())
        .then((data) => {
          setHomePlayers(data?.apiResults?.[0]?.league?.players);
          return data?.apiResults?.[0]?.league?.players;
        });
      let awayPlayers = await fetch(
        (window.ENV?.REACT_APP_OPTA_URL || process.env.REACT_APP_OPTA_URL) +
          "/opta?url=https://api.stats.com/v1/stats/baseball/mlb/participants/teams/" +
          event?.teams?.find((t) => t.teamLocationType?.name === "away")?.teamId
      )
        ?.then((res) => res.json())
        .then((data) => {
          setAwayPlayers(data?.apiResults?.[0]?.league?.players);
          return data?.apiResults?.[0]?.league?.players;
        });

      let player_data = await fetch(
        (window.ENV?.REACT_APP_OPTA_URL || process.env.REACT_APP_OPTA_URL) +
          "/opta?url=https://api.stats.com/v1/stats/baseball/mlb/stats/players/" +
          playerID
      )
        ?.then((res) => res.json())
        .then((data) => {
          setPlayerData({
            ...[...(homePlayers ?? []), ...(awayPlayers ?? [])]?.find(
              (p) => parseInt(p.playerId) === parseInt(playerID)
            ),
            ...data?.apiResults?.[0]?.league?.players?.[0]?.seasons?.[0]
              ?.eventType?.[0]?.splits?.[0]?.playerStats,
          });
          return {
            ...[...(homePlayers ?? []), ...(awayPlayers ?? [])]?.find(
              (p) => parseInt(p.playerId) === parseInt(playerID)
            ),
            ...data?.apiResults?.[0]?.league?.players?.[0]?.seasons?.[0]
              ?.eventType?.[0]?.splits?.[0]?.playerStats,
          };
        });
    }
  }
  function getPaths(object, key) {
    let paths = [];
    if (object) {
      let keys = Object.keys(object);
      for (let i = 0; i < keys.length; i++) {
        console.log(typeof object[keys[i]] === "object");

        if (typeof object[keys[i]] === "object") {
          paths = paths.concat(getPaths(object[keys[i]], keys[i]));
        } else {
          paths.push((key ? key + "." : "") + keys[i]);
        }
      }
    }
    return paths;
  }
  return (
    <EditArea>
      <Input
        value={widget?.date}
        onChange={(e) => {
          let new_widgets = JSON.parse(JSON.stringify(optaWidgets));
          let index = new_widgets?.findIndex((o) => o.name === "mlb");
          let new_widget = {
            ...new_widgets[index],
            name: "mlb",
            date: e.currentTarget.value,
          };
          if (index === -1) {
            new_widgets.push({ name: "mlb" });
          } else {
            new_widgets[index] = new_widget;
          }
          // setDate(e.currentTarget.value);
          setOptaWidgets(new_widgets);
        }}
        placeholder="YYYYMMDD"
      />
      <Dropown
        onChange={(e) => {
          let new_widgets = JSON.parse(JSON.stringify(optaWidgets));
          let index = new_widgets?.findIndex((o) => o.name === "mlb");
          let new_widget = {
            ...new_widgets[index],
            name: "mlb",
            event_id: e.currentTarget.value,
          };
          if (index === -1) {
            new_widgets.push({ name: "mlb" });
          } else {
            new_widgets[index] = new_widget;
          }
          setOptaWidgets(new_widgets);
        }}
      >
        <option disabled selected={!widget?.event_id} value>
          -- select event --
        </option>
        {events?.map((item) => {
          return (
            <DropownOption
              value={item?.eventId}
              selected={parseInt(widget?.event_id) === parseInt(item?.eventId)}
            >
              {
                item?.teams?.find((t) => t.teamLocationType?.name === "home")
                  .nickname
              }{" "}
              v{" "}
              {
                item?.teams?.find((t) => t.teamLocationType?.name === "away")
                  .nickname
              }
            </DropownOption>
          );
        })}
      </Dropown>
      <Dropown
        onChange={async (e) => {
          let new_widgets = JSON.parse(JSON.stringify(optaWidgets));
          let index = new_widgets?.findIndex((o) => o.name === "mlb");
          let new_widget = {
            ...new_widgets[index],
            name: "mlb",
            player_id: e.currentTarget.value,
          };
          if (index === -1) {
            new_widgets.push({ name: "mlb" });
          } else {
            new_widgets[index] = new_widget;
          }

          setOptaWidgets(new_widgets);

          let new_edits = await updateOpta({ opta_widget: new_widget, edits });
          setEdits(new_edits);
          preview();
        }}
      >
        <option disabled selected={!widget?.player_id} value>
          -- select player --
        </option>
        {[...(homePlayers ?? []), ...(awayPlayers ?? [])]?.map((item) => {
          return (
            <DropownOption
              value={item?.playerId}
              selected={
                widget?.player_id &&
                parseInt(widget?.player_id) === parseInt(item?.playerId)
              }
            >
              {/* {item?.playerId} - */}
              {item?.firstName} {item?.lastName}
            </DropownOption>
          );
        })}
      </Dropown>
      {/* <Dropown
        onChange={async (e) => {
          let new_widgets = JSON.parse(JSON.stringify(optaWidgets));
          let index = new_widgets?.findIndex((o) => o.name === "mlb");
          let new_widget = {
            ...new_widgets[index],
            name: "mlb",
            [selectedEdit.uuid]: e.currentTarget.value,
          };
          if (index === -1) {
            new_widgets.push({ name: "mlb" });
          } else {
            new_widgets[index] = new_widget;
          }
          setOptaWidgets(new_widgets);

          let new_edits = await updateOpta({ opta_widget: new_widget, edits });

          setEdits(new_edits);
          preview();
        }}
      >
        <option disabled selected={!widget?.[selectedEdit?.uuid]} value>
          -- select data mapping --
        </option>
        <DropownOption
          value={"none"}
          selected={widget?.[selectedEdit?.uuid] === "none"}
        >
          ------ No Mapping -------
        </DropownOption>
        {playerData &&
          getPaths(playerData)?.map((key) => {
            return (
              <DropownOption
                value={key}
                selected={widget?.[selectedEdit?.uuid] === key}
              >
                {key}
              </DropownOption>
            );
          })}
      </Dropown> */}
      {/* <span style={{ marginTop: "1em" }}>
        {_.get(playerData, selectedEdit?.optaWidget?.variable)}
      </span> */}
    </EditArea>
  );
}

export default GameSelect;
